export class BrowserDaoService {
    public static downloadUrl(data: Blob | string, dialogOptions: Electron.SaveDialogOptions, fileName: string): void {
        const downloadUrl: string = BrowserDaoService.getFileUrl(data);
        const downloadAnchorNode: HTMLAnchorElement = document.createElement('a');
        downloadAnchorNode.href = downloadUrl;
        downloadAnchorNode.download = dialogOptions.defaultPath ? dialogOptions.defaultPath : fileName;
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        window.URL.revokeObjectURL(downloadUrl);
        downloadAnchorNode.remove();
    }

    public static downloadFile(data: Blob | string, dialogOptions: Electron.SaveDialogOptions): Promise<void> | null {
        if (data instanceof Blob) {
            BrowserDaoService.downloadUrl(data, dialogOptions, '');

            return null;
        }

        return fetch(data)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network Error');
                }
                const contentDisposition: string | null = response.headers.get('Content-Disposition');
                const filename: string = BrowserDaoService.getFilename(contentDisposition);

                return response.blob().then((blob) => ({ fileName: filename, blob }));
            })
            .then(({ fileName, blob }) => {
                BrowserDaoService.downloadUrl(blob, dialogOptions, fileName);
            });
    }

    private static getFileUrl(data: Blob | string) {
        if (typeof data === 'string') {
            return data;
        }

        if (data instanceof Blob) {
            return window.URL.createObjectURL(data);
        }

        throw new Error('bad url');
    }

    public static getFilename(contentDisposition: string | null): string {
        let filename: string = '';
        if (contentDisposition) {
            const parts = contentDisposition.split(';');
            for (const part of parts) {
                if (part.trim().startsWith('filename=')) {
                    filename = part.split('=')[1].trim();
                    filename = filename.replace(/["']/g, '');
                    break;
                }
            }
        }

        return window.decodeURI(filename);
    }
}
