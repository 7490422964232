import React, { ChangeEventHandler, useId } from 'react';
import theme from './Checkbox.scss';

type TCheckboxProps = {
    checked?: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    children?: JSX.Element | string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onClick?: (event: React.MouseEvent) => void;
    dataTest?: string;
};

/**
 * @param checked checkbox checked
 * @param indeterminate неопределенный. Имеет приоритет ниже checked
 * @param disabled делает чекбокс недоступным для клика, checked и indeterminate сохраняются
 * @param children текст справа от чекбокса
 * @param onChange вызывается при изменении состояния чекбокса
 * @param onClick вызывается при изменении клика по чекбоксу
 */
export const Checkbox = (props: TCheckboxProps) => {
    const { checked, indeterminate, disabled, children, onChange, onClick } = props;
    const id = useId();

    return (
        <div className={`${theme.checkboxWrapper} ${checked === undefined && indeterminate && theme.indeterminate}`} data-test={props.dataTest}>
            <label
                htmlFor={id}
                onClick= {onClick}
            >
                <input
                    id={id}
                    type="checkbox"
                    checked={checked ?? indeterminate}
                    disabled={disabled}
                    onChange={onChange}
                    data-test={props['data-test']}
                />
                <span>
                    <div className={theme.checkboxLabel}>{children}</div>
                </span>
            </label>
        </div>
    );
};
